import clsx from "clsx";

import { useEffect, useState } from "react";
import ArrowRight from "~/assets/svg/ArrowRight";
import Heart from "~/assets/svg/Heart";
import Instagram from "~/assets/svg/Instagram";
import LinkedIn from "~/assets/svg/LinkedIn";
import LomaInLove from "~/assets/svg/LomaInLove";
import Tiktok from "~/assets/svg/Tiktok";
import Wave from "~/assets/svg/Wave";
import { useTryForFreeLink } from "~/hooks/useTryForFreeLink";
import { events, track } from "~/services/analytics";
import GreenLink from "./GreenLink";

export interface FooterProps {
  hasLoma?: boolean;
  color?: "orange" | "gold";
}

const Footer = ({ hasLoma = true, color = "orange" }: FooterProps) => {
  const [waveStyle, setWaveStyle] = useState("block");

  // On mobile safari we have to re-render the wave
  // for some reason.
  useEffect(() => {
    setTimeout(() => {
      setWaveStyle("inline-block");
    });
  }, []);

  const link = useTryForFreeLink();

  return (
    <footer
      className={clsx([
        "relative mt-56 w-full",
        "xl:mt-96",
        `${color === "orange" ? "bg-orange-light" : "bg-gold-lightest"}`,
      ])}
    >
      <div className="absolute top-0 mt-[1px] w-full -translate-y-full">
        <Wave
          width="100%"
          height="auto"
          style={{
            display: waveStyle,
          }}
          fill={color === "orange" ? "#FFDA9D" : "#FFCD6C"}
        />
      </div>
      {hasLoma && (
        <div
          className={clsx([
            "translate-x-1/6 absolute top-0 w-1/3 -translate-y-[87%]",
            "lg:w-3/12 lg:translate-x-1/4",
          ])}
        >
          <LomaInLove
            width="100%"
            height="auto"
          />
        </div>
      )}
      <div className={clsx(["container relative mx-auto pt-16", "xl:pt-24"])}>
        {/* Hearts */}
        <div className="absolute left-2 top-1/3 w-1/12 -rotate-[10deg] 2xl:top-1/2">
          <Heart
            width="100%"
            height="auto"
            fill={color === "gold" ? "#FDB325" : "#FF8811"}
          />
        </div>
        <div className="absolute right-4 top-0 w-1/12">
          <Heart
            width="100%"
            height="auto"
            fill={color === "gold" ? "#FDB325" : "#FF8811"}
          />
        </div>
        <div className="absolute bottom-0 right-2 w-1/12 translate-y-full rotate-[12deg] 2xl:right-12 2xl:translate-y-[175%] 2xl:rotate-[24deg]">
          <Heart
            width="100%"
            height="auto"
            fill={color === "gold" ? "#FDB325" : "#FF8811"}
          />
        </div>

        <h1
          className={clsx([
            "relative mx-auto max-w-[300px] text-center font-logo text-3xl",
            "md:max-w-[500px] md:text-4xl",
            "md:max-w-[760px] lg:text-5xl",
            "2xl:text-7xl",
          ])}
        >
          It’s time to share your love through cooking.
        </h1>
      </div>
      <div className={clsx(["relative flex w-full justify-center py-10"])}>
        <GreenLink
          href={link}
          text="Try for free"
          size="xlarge"
          target="_blank"
          onClick={() => {
            track(events.CLICKED_DOWNLOAD, {
              from: "footer",
            });
          }}
        />
      </div>
      {/* Bottom Links & Socials */}
      <div
        className={clsx([
          "container mx-auto flex flex-col",
          "lg:grid lg:grid-flow-col lg:grid-cols-[auto_auto_1fr] lg:items-center lg:py-12",
        ])}
      >
        <div
          className={clsx([
            "flex flex-col pb-14 font-bold uppercase",
            "lg:col-span-1 lg:col-start-3 lg:ml-auto lg:flex-row lg:pb-0",
          ])}
        >
          <a
            href="/team"
            className={clsx([
              "border-text-text-black mx-10 flex place-content-between items-center border-b py-3",
              "lg:hidden",
            ])}
          >
            Meet the team
            <span className="lg:hidden">
              <ArrowRight />
            </span>
          </a>
          <a
            href="/articles"
            className={clsx([
              "border-text-text-black mx-10 flex place-content-between items-center border-b py-3 hover:underline",
              "lg:border-b-0",
            ])}
          >
            Articles
            <span className="lg:hidden">
              <ArrowRight />
            </span>
          </a>
          <a
            href="/terms"
            className={clsx([
              "border-text-text-black mx-10 flex place-content-between items-center border-b py-3 hover:underline",
              "lg:border-b-0",
            ])}
          >
            Terms
            <span className="lg:hidden">
              <ArrowRight />
            </span>
          </a>
          <a
            href="/privacy-policy"
            className={clsx([
              "border-text-text-black mx-10 flex place-content-between items-center border-b py-3 hover:underline",
              "lg:border-b-0",
            ])}
          >
            Privacy Policy
            <span className="lg:hidden">
              <ArrowRight />
            </span>
          </a>
          <a
            href="/cookies"
            className={clsx([
              "border-text-text-black mx-10 flex place-content-between items-center border-b py-3 hover:underline",
              "lg:border-b-0",
            ])}
          >
            Cookies Policy
            <span className="lg:hidden">
              <ArrowRight />
            </span>
          </a>
        </div>

        <div
          className={clsx([
            "container flex flex-row items-center justify-center border-t-2 py-5",
            `${color === "orange" ? "border-orange-main" : "border-gold-main"}`,
            "lg:border-0",
          ])}
        >
          <div className="flex flex-row">
            <a
              href="https://www.instagram.com/zestappcooking/"
              className={clsx([
                "inline-block w-7 transition-opacity hover:opacity-80",
                "lg:w-12",
                "2xl:w-16",
              ])}
              target="_blank"
            >
              <Instagram
                width="100%"
                height="auto"
              />
            </a>
            <a
              href="https://www.tiktok.com/@zestappcooking"
              className={clsx([
                "mx-5 inline-block w-7 transition-opacity hover:opacity-80",
                "lg:w-12",
                "2xl:mx-10 2xl:w-16",
              ])}
              target="_blank"
            >
              <Tiktok
                width="100%"
                height="auto"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/zestapp/"
              className={clsx([
                "inline-block w-7 transition-opacity hover:opacity-80",
                "lg:w-12",
                "2xl:w-16",
              ])}
              target="_blank"
            >
              <LinkedIn
                width="100%"
                height="auto"
              />
            </a>
          </div>
        </div>
        <span
          className={clsx([
            "mx-auto inline-block pb-8 text-center font-medium",
            "lg:col-span-1 lg:col-start-1 lg:mr-6 lg:pb-0",
          ])}
        >
          &copy; Zest {new Date().getFullYear()}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
